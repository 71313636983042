<template>
  <div class="layout-config-body">

      <!--s: header-->
      <div class="config-header">
          <div class="header-left">
              <a href="javascript:" class="back" @click="onBack"><Icon type="ios-arrow-back" /></a>
              <div class="config-center">
                  <Input v-model="configParams.configName" :border="configNameVisible" disabled @on-focus="configNameVisible = true" @on-blur="configNameVisible = false" :class="configNameVisible?'center':'left'"  placeholder="未命名" />
              </div>
          </div>
      </div>
      <!--e: header-->

      <!--s: body-->
      <div class="config-body">

          <div class="canvas-screen-wrap">
              <!--s: config Menu-->
              <div class="config-menu" v-if="JSON.stringify(flowReturnObj)=='{}' || flowReturnObj.status === 0">
                  <div class="box" :key="key" v-for="(config,key) in configList">
                      <div class="menu-thead" :class="!config.visible?'border':''" @click="config.visible = !config.visible">
                          {{ config.name }}
                          <Icon :type="config.visible?'ios-remove':'ios-add'" class="arrow"/>
                      </div>
                      <div class="menu-body" v-if="config.visible">
                          <ul>
                              <li :key="index" v-for="(item,index) in config.childNode"><a href="javascript:" @mousedown="startDrag(item, $event)"><Icon :custom="item.icon" />{{ item.name }}</a></li>
                          </ul>
                      </div>
                  </div>
              </div>
              <!--e: config Menu-->

              <!--s: config body-->
              <div class="config-content" :style="JSON.stringify(flowReturnObj)!='{}' && flowReturnObj.status === 1?'width:100%':''">
                  <div id="container" class="container" :style="flowReturnObj.status === 1?'width:100%':''"></div>
                  <div class="config-thumb">
                      <a href="javascript:" v-if="nodeObj.nodeCode !== NODE_NAME.NODE_START && nodeObj.nodeCode !== NODE_NAME.NODE_END && nodeObj.nodeCode" :class="thumbVisible?'node-narrow':'node-enlarge'" @click="thumbVisible = !thumbVisible"></a>
                      <a href="javascript:" class="enlarge" @click="onThumbEnlarge"></a>
                      <a href="javascript:" class="narrow" @click="onThumbNarrow"></a>

                      <div class="thumb" v-if="thumbVisible && nodeObj.nodeCode !== NODE_NAME.NODE_START && JSON.stringify(nodeObj)!=='{}' && nodeObj.attribute.endSettingsType!=='autoCommit' && nodeObj.nodeCode" :class="nodeObj.nodeCode === NODE_NAME.NODE_IMAGE?nodeObj.attribute.imageMode:''">

                          <!--s: 结束-->
                          <div class="thumb-content" v-if="nodeObj.nodeCode === NODE_NAME.NODE_END">
                              <h1 class="thumb-end-title">结束</h1>
                              <div class="thumb-end-content">
                                  <h1 class="success-text"><i class="icon"></i>任务已完成</h1>
                                  <h2 class="success-content">仍在上传录像，为保证录像数据完整，请不要立即关机。</h2>
                              </div>
                              <div class="thumb-end-time" v-if="nodeObj.attribute.endSettingsType === 'scheduledSubmission'">{{ nodeObj.attribute.timedJumpNumber }}秒后将自动关闭窗口</div>
                              <div class="thumb-end-btn-list">
                                  <span :key="key" v-for="(nodeBtnList,key) in nodeObj.attribute.nodeBtnList" :style="'background:'+nodeBtnList.color">{{ nodeBtnList.buttonText }}</span>
                              </div>
                          </div>
                          <!--e: 结束-->
                          <!--s: 拍照-->
                          <div class="thumb-content" v-if="nodeObj.nodeCode === NODE_NAME.NODE_PHOTO">
                              <h1 class="title">{{ nodeObj.nodeName}}</h1>
                              <div class="text-print-phone" v-if="nodeObj.attribute.timedJumpStatus === 1">{{ nodeObj.attribute.timedJumpNumber }}秒后即将进入拍照</div>
                              <div class="thumb-text">
                                  <div class="thumb-btn-list">
                                      <span v-show="nodeBtnList.buttonCode === defaultButtonName.TRIGGER_PHOTO && !triggerARStatus" :key="key" v-for="(nodeBtnList,key) in nodeObj.attribute.nodeBtnList" :style="'background:'+nodeBtnList.color+';cursor: pointer;'" @click="triggerARStatus = true">{{ nodeBtnList.buttonText }}</span>
                                      <span v-show="nodeBtnList.buttonCode !== defaultButtonName.TRIGGER_PHOTO && triggerARStatus" :key="'trigger'+key" v-for="(nodeBtnList,key) in nodeObj.attribute.nodeBtnList" :style="nodeBtnList.buttonCode === defaultButtonName.NEXT_STEP?'background:'+nodeBtnList.color : 'background:'+nodeBtnList.color+';cursor: pointer;'" @click="nodeBtnList.buttonCode === defaultButtonName.TAKE_NEW_PHOTO? triggerARStatus = false:null">{{ nodeBtnList.buttonText }}</span>
                                  </div>
                              </div>
                              <div class="btn">回到上一级 拍照/上一步</div>
                              <i class="photo-l-t"></i>
                              <i class="photo-l-b"></i>
                              <i class="photo-r-t"></i>
                              <i class="photo-r-b"></i>
                          </div>
                          <!--e: 拍照-->
                          <!--s: AI识别-->
                          <div class="thumb-content" v-if="nodeObj.nodeCode === NODE_NAME.NODE_AI_IDENTIFY">
                              <h1 class="title">{{ nodeObj.nodeName}}</h1>
                              <div class="thumb-text">
                                  <div class="thumb-btn-list">
                                      <span :key="key" v-for="(nodeBtnList,key) in nodeObj.attribute.nodeBtnList" :style="'background:'+nodeBtnList.color">{{ nodeBtnList.buttonText }}</span>
                                  </div>
                              </div>
                              <div class="btn">回到上一级 上一步</div>
                              <i class="photo-l-t"></i>
                              <i class="photo-l-b"></i>
                              <i class="photo-r-t"></i>
                              <i class="photo-r-b"></i>
                              <div class="ai-identify-box"><i class="ai-identify"></i></div>
                          </div>
                          <!--e: AI识别-->

                          <!--s: 环境监测-->
                          <div class="thumb-content" v-if="nodeObj.nodeCode === NODE_NAME.NODE_ENVIRONMENTAL_MONITORING">
                              <h1 class="title">{{ nodeObj.nodeName }}</h1>
                              <div class="thumb-text environmental-bottom">
                                  <div class="environmental-box">
                                      <div class="environmental-box-content">
                                          <Icon custom="mark"></Icon>
                                          <h1 class="title">作业环境数据正常</h1>
                                          <div class="module-box">
                                              <ul>
                                                  <li>
                                                    <span class="title-name">瓦斯</span>
                                                    <span class="box-text">
                                                                        <font>-</font>%
                                                                    </span>
                                                  </li>
                                                  <li>
                                                    <span class="title-name">一氧化碳</span>
                                                    <span class="box-text">
                                                                        <font>-</font>ppm
                                                                    </span>
                                                  </li>
                                                  <li>
                                                    <span class="title-name">温度</span>
                                                    <span class="box-text">
                                                                        <font>-</font>°C
                                                                    </span>
                                                  </li>
                                              </ul>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="thumb-btn-list">
                                      <span :key="key" v-for="(nodeBtnList,key) in nodeObj.attribute.nodeBtnList" :style="'background:'+nodeBtnList.color">{{ nodeBtnList.buttonText }}</span>
                                  </div>
                              </div>
                              <div class="btn">回到上一级 上一步</div>
                          </div>
                          <!--e: 环境监测-->

                          <!--s: 设备状态-->
                          <div class="thumb-content" v-if="nodeObj.nodeCode === NODE_NAME.NODE_DEVICE_STATUS">
                              <h1 class="title">{{ nodeObj.nodeName}}</h1>
                              <div class="thumb-text">
                                  <div class="device-name">对准{{nodeObj.attribute.deviceName}}至识别框内</div>
                                  <div class="thumb-btn-list">
                                      <span :key="key" v-for="(nodeBtnList,key) in nodeObj.attribute.nodeBtnList" :style="'background:'+nodeBtnList.color">{{ nodeBtnList.buttonText }}</span>
                                  </div>
                              </div>
                              <div class="btn">回到上一级 上一步</div>
                              <i class="photo-l-t"></i>
                              <i class="photo-l-b"></i>
                              <i class="photo-r-t"></i>
                              <i class="photo-r-b"></i>
                          </div>
                          <!--e: 设备状态-->

                          <!--s: 语音输入-->
                          <div class="thumb-content" v-if="nodeObj.nodeCode === NODE_NAME.NODE_VOICE_INPUT">
                              <h1 class="title">{{ nodeObj.nodeName }}</h1>
                              <div class="thumb-text">
                                  <span class="name"><Icon custom="voice" /><font class="font" v-if="nodeObj.attribute.inputTypeCode === 'number'">{{ nodeObj.attribute.unitType }}</font></span>
                                  <div class="text-print" v-if="nodeObj.attribute.timedJumpStatus === 1">{{ nodeObj.attribute.timedJumpNumber }}秒后即将进入下一步</div>
                                  <div class="thumb-btn-list">
                                      <span v-show="nodeBtnList.buttonCode === defaultButtonName.START_RECORDING && !triggerARStatus" :key="key" v-for="(nodeBtnList,key) in nodeObj.attribute.nodeBtnList" :style="'background:'+nodeBtnList.color+';cursor: pointer;'" @click="triggerARStatus = true">{{ nodeBtnList.buttonText }}</span>
                                      <span v-show="nodeBtnList.buttonCode !== defaultButtonName.START_RECORDING && triggerARStatus" :key="'recording'+key" v-for="(nodeBtnList,key) in nodeObj.attribute.nodeBtnList" :style="nodeBtnList.buttonCode === defaultButtonName.PLAY_RECORDING || nodeBtnList.buttonCode === defaultButtonName.STOP_PLAY_RECORDING || nodeBtnList.buttonCode === defaultButtonName.NEXT_STEP?'background:'+nodeBtnList.color : 'background:'+nodeBtnList.color+';cursor: pointer;'" @click="nodeBtnList.buttonCode === defaultButtonName.RECORDING_AGAIN? triggerARStatus = false:null">{{ nodeBtnList.buttonText }}</span>
                                  </div>
                              </div>
                          </div>
                          <!--e: 语音输入-->
                          <!--s: 文本-->
                          <div class="thumb-content" v-if="nodeObj.nodeCode === NODE_NAME.NODE_TEXT">
                              <h1 class="title">{{ nodeObj.nodeName }}</h1>
                              <div class="thumb-text">
                                  <span class="name" v-html="nodeObj.attribute.nodeContent || '输入文本'"></span>
                                  <div class="text-print" v-if="nodeObj.attribute.timedJumpStatus === 1">{{ nodeObj.attribute.timedJumpNumber }}秒后即将进入下一步</div>
                                  <div class="thumb-btn-list">
                                      <span :key="key" v-for="(nodeBtnList,key) in nodeObj.attribute.nodeBtnList" :style="'background:'+nodeBtnList.color">{{ nodeBtnList.buttonText }}</span>
                                  </div>
                              </div>
                              <div class="btn">上一步<span :key="key" v-for="(nodeBtnList,key) in nodeObj.attribute.nodeBtnList">/{{ nodeBtnList.buttonText }}</span></div>
                          </div>
                          <!--e: 文本-->
                          <!--s: 图片-->
                          <div class="thumb-content" v-if="nodeObj.nodeCode === NODE_NAME.NODE_IMAGE">
                              <h1 class="title">{{ nodeObj.nodeName }}</h1>
                              <div class="thumb-image">
                                  <div class="text-info">
                                      <span class="image" v-if="nodeObj.attribute.imageUrl !==''" :style="'background-image:url('+nodeObj.attribute.imageUrl+')'"></span>
                                      <div class="image-print" v-if="nodeObj.attribute.imageUrl === ''">
                                          <div class="box">
                                              <span><Icon custom="icon-config-image"/></span>
                                              <span>请上传图片</span>
                                          </div>
                                      </div>
                                      <p class="text">{{ nodeObj.attribute.nodeContent || '作业内容' }}</p>
                                  </div>
                                  <div class="text-print" v-if="nodeObj.attribute.timedJumpStatus === 1">{{ nodeObj.attribute.timedJumpNumber }}秒后即将进入下一步</div>
                                  <div class="thumb-btn-list" :style="nodeObj.attribute.timedJumpStatus !== 1?'margin-top:15px':''">
                                      <span :key="key" v-for="(nodeBtnList,key) in nodeObj.attribute.nodeBtnList" v-show="(nodeBtnList.buttonText === '查看图片' && nodeBtnList.buttonLookImageStatus ===1) || nodeBtnList.buttonText !== '查看图片'" :style="'background:'+nodeBtnList.color">{{ nodeBtnList.buttonText }}</span>
                                  </div>
                              </div>
                            <!--                              <div class="btn">上一步<span :key="key" v-for="(nodeBtnList,key) in nodeObj.attribute.nodeBtnList">/{{ nodeBtnList.buttonText }}</span></div>-->
                          </div>
                          <!--e: 图片-->
                          <!--s: 批量上传-->
                          <div class="thumb-content" v-if="nodeObj.nodeCode === NODE_NAME.NODE_BATCH_OPERATION">
                              <h1 class="title">{{ nodeObj.nodeName }}</h1>
                              <div class="thumb-batch-no-data" v-if="nodeObj.attribute.batchList.length===0"></div>
                              <div class="thumb-batch" v-if="nodeObj.attribute.batchList.length!==0">
                                  <div class="thumb-batch-box">
                                      <div class="li" :key="key" v-for="(item,key) in nodeObj.attribute.batchList">
                                          <span class="number">{{ key+1 }}.</span>
                                          <span class="info">{{ item.entryName }}：{{ item.textInfo }}</span>
                                          <div class="btn-list">
                                              <div class="box">
                                                  <span :key="'btnList'+index" v-for="(btnList,index) in item.btnList">{{ btnList.buttonText }}</span>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div class="thumb-batch-btn" v-if="nodeObj.attribute.batchList.length!==0">
                                  <span :key="key" v-for="(nodeBtnList,key) in nodeObj.attribute.nodeBtnList" :style="'background:'+nodeBtnList.color">{{ nodeBtnList.buttonText }}</span>
                              </div>
                              <div class="btn" v-if="nodeObj.attribute.batchList.length!==0">上一步/打开第1、2.../上一页/下一页/第1、2...+按钮名称</div>
                          </div>
                          <!--e: 批量上传-->
                          <!--s: 批量输入-->
                          <div class="thumb-content batch-input-content" v-if="nodeObj.nodeCode === NODE_NAME.NODE_BATCH_INPUT">
                              <h1 class="title">{{ nodeObj.nodeName }}</h1>
                              <div class="thumb-batch-no-data" v-if="nodeObj.attribute.batchInputList.length===0"></div>
                              <div class="thumb-batch" v-if="nodeObj.attribute.batchInputList.length!==0">
                                  <div class="thumb-batch-box">
                                      <div class="li" :key="key" v-for="(item,key) in nodeObj.attribute.batchInputList">
                                          <span class="number">{{ key+1 }}.</span>
                                          <span class="info">{{ item.entryName }}：{{ item.textInfo }}</span>
                                          <div class="btn-list">
                                              <div class="box">
                                                  <span>开始录音</span>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div class="thumb-batch-btn" v-if="nodeObj.attribute.batchInputList.length!==0">
                                  <span :key="key" v-for="(nodeBtnList,key) in nodeObj.attribute.nodeBtnList" :style="'background:'+nodeBtnList.color">{{ nodeBtnList.buttonText }}</span>
                              </div>
                              <div class="btn" v-if="nodeObj.attribute.batchInputList.length!==0">上一步/打开第1、2.../上一页/下一页/第1、2...+按钮名称</div>
                          </div>
                          <!--e: 批量输入-->
                          <Spin fix v-if="systemLoading"></Spin>
                      </div>
                  </div>
              </div>
              <!--e: config body-->
          </div>

          <!--s: config system-->
          <div class="config-system">
              <Tabs size="default">
                  <TabPane label="控制设置">
                      <div class="system-content" :class="JSON.stringify(flowReturnObj)!='{}' && flowReturnObj.status === 1?'spin':''">
                          <div class="system-print" v-if="nodeObj.nodeCode === NODE_NAME.NODE_START && !printVisible">开始节点、子流程节点没有属性</div>
                          <span class="print" v-if="printVisible">未选中模版</span>
                          <span class="print" v-if="startLineVisible">该连接线无需配置触发条件</span>

                          <!--s: 线条配置项-->
                          <div class="system-sub" v-if="!printVisible && lineVisible">
                              <ul>
                                  <li>
                                      <span class="label">触发判断条件<font class="font">*</font></span>
                                      <CheckboxGroup v-model="lineSelectList">
                                          <Checkbox v-show="!btnList.regularButton" :label="btnList.id" :disabled="btnList.lineId!=''&&lineId !== btnList.lineId" :key="key" v-for="(btnList,key) in sourceBtnList" @change.native="onLineCheckBox">{{ btnList.buttonText }}</Checkbox>
                                      </CheckboxGroup>
                                  </li>
                              </ul>
                          </div>
                          <!--e: 线条配置项-->
                          <!--s: 结束-->
                          <div class="system-sub" v-if="nodeObj.nodeCode === NODE_NAME.NODE_END && !printVisible">
                              <ul>
                                  <li>
                                      <span class="label">结束设置</span>
                                  </li>
                                  <li>
                                      <div class="system-input-li">
                                          <Dropdown trigger="click" >
                                              <a href="javascript:">
                                                  {{ nodeObj.attribute.endSettingsText }}
                                                  <Icon type="ios-arrow-down"></Icon>
                                              </a>
                                              <DropdownMenu slot="list">
                                                  <DropdownItem :key="key" v-for="(type,key) in endSettingsType" >{{ type.typeName }}</DropdownItem>
                                              </DropdownMenu>
                                          </Dropdown>
                                      </div>
                                      <div class="info">
                                          <Icon custom="info-icon"></Icon>
                                          {{ nodeObj.attribute.endSettingsType === 'popupConfirmation'? '任务结束时会弹窗提示是否提交':'' }}
                                          {{ nodeObj.attribute.endSettingsType === 'scheduledSubmission'? '任务结束时显示提交窗口，并定时关闭窗口':'' }}
                                          {{ nodeObj.attribute.endSettingsType === 'autoCommit'? '任务结束时会自动提交，不现实提示窗口':'' }}
                                      </div>
                                  </li>
                                  <li>
                                      <div class="time" v-if="nodeObj.attribute.endSettingsType === 'scheduledSubmission'">
                                          <span class="name">延迟时间（秒）</span>
                                          <div class="input-number">
                                              <Input-number :max="1000" :min="1" v-model="nodeObj.attribute.timedJumpNumber" @on-change="$forceUpdate()"></Input-number>
                                          </div>
                                      </div>
                                  </li>
                                  <li>
                                      <div class="title"><span class="label">按钮 </span></div>
                                      <div class="list">
                                          <div class="li" :key="'n'+key" v-for="(item,key) in nodeObj.attribute.nodeBtnList">
                                              <div class="color-item">
                                                  <Dropdown trigger="click" >
                                                      <a href="javascript:void(0)" class="code" :style="'background:'+item.color"></a>
                                                      <DropdownMenu slot="list">
                                                          <DropdownItem :key="'c'+index" v-for="(color,index) in colorList" :style="'background:'+color.color" @click.native="onColor(color,key)"></DropdownItem>
                                                      </DropdownMenu>
                                                  </Dropdown>
                                              </div>
                                              <div class="text-item regular-item">
                                                  <Dropdown @on-click="onText($event,key)">
                                                      <a href="javascript:void(0)">
                                                          <input v-model="item.buttonText" @blur="onButtonTextCheck(item,key)" type="text" class="item-input-regular" />
                                                          <Icon type="ios-arrow-down"></Icon>
                                                      </a>
                                                      <DropdownMenu slot="list">
                                                          <DropdownItem :name="btn.btnName" :disabled="onButtonTextRepeat(btn.btnName,key) || item.buttonText === btn.btnName" :key="'b'+j" v-for="(btn,j) in btnText">{{ btn.btnName }}</DropdownItem>
                                                      </DropdownMenu>
                                                  </Dropdown>
                                              </div>
                                        </div>
                                    </div>
                                    <div class="info">
                                      <Icon custom="info-icon"></Icon>按钮文字只能是数字、汉字或者数字和汉字的组合
                                    </div>
                                  </li>
                              </ul>
                          </div>
                          <!--e: 结束-->
                          <!--s: 拍照-->
                          <div class="system-sub" v-if="nodeObj.nodeCode === NODE_NAME.NODE_PHOTO && !printVisible">
                              <ul>
                                  <li>
                                      <span class="label">步骤名称<font class="font">*</font></span>
                                      <Input v-model="nodeObj.nodeName" show-word-limit maxlength="20" placeholder="请输入" @on-keyup="onNodeName"/>
                                  </li>
                                  <!--                                  <li>-->
                                  <!--                                      <span class="label">标题</span>-->
                                  <!--                                      <Input v-model="nodeObj.attribute.nodeTitle" placeholder="请输入" @on-keyup="onNodeTitle"/>-->
                                  <!--                                  </li>-->
                                  <li>
                                      <div class="voice-switch">
                                          <span class="name">语音播报</span>
                                          <div class="switch">
                                              <onSwitch v-model="nodeObj.attribute.voiceAnnouncements" :true-value="1" :false-value="0" @on-change="$forceUpdate()" size="small"/>
                                          </div>
                                      </div>
                                  </li>
                                  <li v-if="nodeObj.attribute.voiceAnnouncements === 1">
                                      <div class="system-input-li">
                                          <Dropdown trigger="click" >
                                              <a href="javascript:">
                                                  {{ nodeObj.attribute.announcementsText }}
                                                  <Icon type="ios-arrow-down"></Icon>
                                              </a>
                                              <DropdownMenu slot="list">
                                                  <DropdownItem v-show="type.typeCode!=='nodeNameNodeContent'" :key="key" v-for="(type,key) in announcementsType"  @click.native="onAnnouncementsSwitch(type)">{{ type.typeName }}</DropdownItem>
                                              </DropdownMenu>
                                          </Dropdown>
                                      </div>
                                  </li>
                                  <li v-if="nodeObj.attribute.voiceAnnouncements === 1 && nodeObj.attribute.announcementsType === 'customContent'">
                                      <Input maxlength="50" show-word-limit :rows="4" type="textarea" v-model="nodeObj.attribute.customText" placeholder="请输入" @on-keyup="onAnnouncementsContent"/>
                                  </li>
                                  <li>
                                      <div class="voice-switch">
                                          <span class="name">定时拍照</span>
                                          <div class="switch">
                                              <onSwitch v-model="nodeObj.attribute.cameraTimerStatus" :true-value="1" :false-value="0" @on-change="$forceUpdate()" size="small"/>
                                          </div>
                                      </div>
                                      <div class="info time-margin-top">
                                          <Icon custom="info-icon"></Icon>开启后，步骤会定时触发"拍照"按钮
                                      </div>
                                      <div class="time" v-if="nodeObj.attribute.cameraTimerStatus === 1">
                                          <span class="name">延迟时间（秒）</span>
                                          <div class="input-number">
                                              <Input-number :max="1000" :min="1" v-model="nodeObj.attribute.cameraTimerNumber" @on-change="$forceUpdate()"></Input-number>
                                          </div>
                                      </div>
                                  </li>
                                  <li>
                                      <div class="voice-switch">
                                          <span class="name">定时跳转</span>
                                          <div class="switch">
                                              <onSwitch v-model="nodeObj.attribute.timedJumpStatus" :true-value="1" :false-value="0" @on-change="$forceUpdate()" size="small"/>
                                          </div>
                                      </div>
                                      <div class="info time-margin-top">
                                          <Icon custom="info-icon"></Icon>开启后，拍照完成会定时进入下一步
                                      </div>
                                      <div class="time" v-if="nodeObj.attribute.timedJumpStatus === 1">
                                          <span class="name">延迟时间（秒）</span>
                                          <div class="input-number">
                                              <Input-number :max="1000" :min="1" v-model="nodeObj.attribute.timedJumpNumber" @on-change="$forceUpdate()"></Input-number>
                                          </div>
                                      </div>
                                  </li>
                                  <li>
                                      <div class="title"><span class="label">按钮 </span></div>
                                      <div class="list">
                                          <div class="li" :key="'n'+key" v-for="(item,key) in nodeObj.attribute.nodeBtnList">
                                              <div class="color-item">
                                                  <Dropdown trigger="click" >
                                                      <a href="javascript:void(0)" class="code" :style="'background:'+item.color"></a>
                                                      <DropdownMenu slot="list">
                                                          <DropdownItem :key="'c'+index" v-for="(color,index) in colorList" :style="'background:'+color.color" @click.native="onColor(color,key)"></DropdownItem>
                                                      </DropdownMenu>
                                                  </Dropdown>
                                              </div>
                                              <div class="text-item regular-item">
                                                  <Dropdown @on-click="onText($event,key)">
                                                      <a href="javascript:void(0)">
                                                          <input v-model="item.buttonText" @blur="onButtonTextCheck(item,key)" type="text" class="item-input-regular" />
                                                          <Icon type="ios-arrow-down"></Icon>
                                                      </a>
                                                      <DropdownMenu slot="list">
                                                          <DropdownItem :name="btn.btnName" :disabled="onButtonTextRepeat(btn.btnName,key) || item.buttonText === btn.btnName" :key="'b'+j" v-for="(btn,j) in btnText">{{ btn.btnName }}</DropdownItem>
                                                      </DropdownMenu>
                                                  </Dropdown>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="info">
                                          <Icon custom="info-icon"></Icon>按钮文字只能是数字、汉字或者数字和汉字的组合
                                      </div>
                                  </li>
                              </ul>
                          </div>
                          <!--e: 拍照-->
                          <!--s: 语音输入-->
                          <div class="system-sub" v-if="nodeObj.nodeCode === NODE_NAME.NODE_VOICE_INPUT && !printVisible">
                              <ul>
                                  <li>
                                      <span class="label">步骤名称<font class="font">*</font></span>
                                      <Input v-model="nodeObj.nodeName" show-word-limit maxlength="20" placeholder="请输入" @on-keyup="onNodeName"/>
                                  </li>
                                  <!--                                  <li>-->
                                  <!--                                      <span class="label">标题</span>-->
                                  <!--                                      <Input v-model="nodeObj.attribute.nodeTitle" placeholder="请输入" @on-keyup="onNodeTitle"/>-->
                                  <!--                                  </li>-->
                                  <li>
                                      <span class="label">输入类型<font class="font">*</font></span>
                                      <div class="system-input-li">
                                          <Dropdown trigger="click" >
                                              <a href="javascript:">
                                                  {{ nodeObj.attribute.inputTypeName }}
                                                  <Icon type="ios-arrow-down"></Icon>
                                              </a>
                                              <DropdownMenu slot="list">
                                                  <DropdownItem :key="key" v-for="(input,key) in inputType" :disabled="nodeObj.attribute.inputTypeCode === input.typeCode" @click.native="onInputTypeSwitch(input)">{{ input.typeName }}</DropdownItem>
                                              </DropdownMenu>
                                          </Dropdown>
                                      </div>
                                  </li>
                                  <li v-if="nodeObj.attribute.inputTypeCode === 'number'">
                                      <span class="label">单位<font class="font">*</font></span>
                                      <div class="system-input-li">
                                          <Dropdown trigger="click" >
                                              <a href="javascript:">
                                                  <input v-model="nodeObj.attribute.unitType" @blur="onUnitTypeCheck" type="text" class="item-input" />
                                                  <Icon type="ios-arrow-down"></Icon>
                                              </a>
                                              <DropdownMenu slot="list">
                                                  <DropdownItem :key="key" v-for="(unit,key) in unitType" :disabled="nodeObj.attribute.unitType === unit.unitType" @click.native="onUnitTypeSwitch(unit)">{{ unit.unitType }}</DropdownItem>
                                              </DropdownMenu>
                                          </Dropdown>
                                      </div>
                                  </li>
                                  <li>
                                      <div class="voice-switch">
                                          <span class="name">语音播报</span>
                                          <div class="switch">
                                              <onSwitch v-model="nodeObj.attribute.voiceAnnouncements" :true-value="1" :false-value="0" @on-change="$forceUpdate()" size="small"/>
                                          </div>
                                      </div>
                                  </li>
                                  <li v-if="nodeObj.attribute.voiceAnnouncements === 1">
                                      <div class="system-input-li">
                                          <Dropdown trigger="click" >
                                              <a href="javascript:">
                                                  {{ nodeObj.attribute.announcementsText }}
                                                  <Icon type="ios-arrow-down"></Icon>
                                              </a>
                                              <DropdownMenu slot="list">
                                                  <DropdownItem v-show="type.typeCode!=='nodeNameNodeContent'" :key="key" v-for="(type,key) in announcementsType"  @click.native="onAnnouncementsSwitch(type)">{{ type.typeName }}</DropdownItem>
                                              </DropdownMenu>
                                          </Dropdown>
                                      </div>
                                  </li>
                                  <li v-if="nodeObj.attribute.voiceAnnouncements === 1 && nodeObj.attribute.announcementsType === 'customContent'">
                                      <Input maxlength="50" show-word-limit type="textarea" v-model="nodeObj.attribute.customText" placeholder="请输入" @on-keyup="onAnnouncementsContent"/>
                                  </li>
                                  <li>
                                      <div class="voice-switch">
                                          <span class="name">定时录音</span>
                                          <div class="switch">
                                              <onSwitch v-model="nodeObj.attribute.timedRecordingStatus" :true-value="1" :false-value="0" @on-change="$forceUpdate()" size="small"/>
                                          </div>
                                      </div>
                                      <div class="info time-margin-top">
                                          <Icon custom="info-icon"></Icon>开启后，步骤会定时触发"录音"按钮
                                      </div>
                                      <div class="time" v-if="nodeObj.attribute.timedRecordingStatus === 1">
                                          <span class="name">延迟时间（秒）</span>
                                          <div class="input-number">
                                              <Input-number :max="1000" :min="1" v-model="nodeObj.attribute.timedRecordingNumber" @on-change="$forceUpdate()"></Input-number>
                                          </div>
                                      </div>
                                  </li>
                                  <li>
                                      <div class="voice-switch">
                                          <span class="name">定时跳转</span>
                                          <div class="switch">
                                              <onSwitch v-model="nodeObj.attribute.timedJumpStatus" :true-value="1" :false-value="0" @on-change="$forceUpdate()" size="small"/>
                                          </div>
                                      </div>
                                      <div class="info time-margin-top">
                                          <Icon custom="info-icon"></Icon>开启后，录音完成会定时进入下一步
                                      </div>
                                      <div class="time" v-if="nodeObj.attribute.timedJumpStatus === 1">
                                          <span class="name">延迟时间（秒）</span>
                                          <div class="input-number">
                                              <Input-number :max="1000" :min="1" v-model="nodeObj.attribute.timedJumpNumber" @on-change="$forceUpdate()"></Input-number>
                                          </div>
                                      </div>
                                  </li>
                                  <li>
                                      <div class="title"><span class="label">按钮 </span></div>
                                      <div class="list">
                                          <div class="li" :key="'n'+key" v-for="(item,key) in nodeObj.attribute.nodeBtnList">
                                              <div class="color-item">
                                                  <Dropdown trigger="click" >
                                                      <a href="javascript:void(0)" class="code" :style="'background:'+item.color"></a>
                                                      <DropdownMenu slot="list">
                                                          <DropdownItem :key="'c'+index" v-for="(color,index) in colorList" :style="'background:'+color.color" @click.native="onColor(color,key)"></DropdownItem>
                                                      </DropdownMenu>
                                                  </Dropdown>
                                              </div>
                                              <div class="text-item regular-item">
                                                  <Dropdown @on-click="onText($event,key)">
                                                      <a href="javascript:void(0)">
                                                          <input v-model="item.buttonText" @blur="onButtonTextCheck(item,key)" type="text" class="item-input-regular" />
                                                          <Icon type="ios-arrow-down"></Icon>
                                                      </a>
                                                      <DropdownMenu slot="list">
                                                          <DropdownItem :name="btn.btnName" :disabled="onButtonTextRepeat(btn.btnName,key) || item.buttonText === btn.btnName" :key="'b'+j" v-for="(btn,j) in btnText">{{ btn.btnName }}</DropdownItem>
                                                      </DropdownMenu>
                                                  </Dropdown>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="info">
                                          <Icon custom="info-icon"></Icon>按钮文字只能是数字、汉字或者数字和汉字的组合
                                      </div>
                                  </li>
                              </ul>
                          </div>
                          <!--e: 语音输入-->
                          <!--s: AI识别-->
                          <div class="system-sub" v-if="nodeObj.nodeCode === NODE_NAME.NODE_AI_IDENTIFY && !printVisible">
                              <ul>
                                  <li>
                                      <span class="label">步骤名称<font class="font">*</font></span>
                                      <Input v-model="nodeObj.nodeName" show-word-limit maxlength="20" placeholder="请输入"/>
                                  </li>
                                  <!--                                  <li>-->
                                  <!--                                      <span class="label">标题</span>-->
                                  <!--                                      <Input v-model="nodeObj.attribute.nodeTitle" placeholder="请输入" @on-keyup="onNodeTitle"/>-->
                                  <!--                                  </li>-->
                                  <li>
                                      <span class="label">模型名称<font class="font">*</font></span>
                                      <Input v-model="nodeObj.attribute.modelName" show-word-limit :maxlength="30" type="textarea" :rows="4" placeholder="请输入"/>
                                  </li>
                                  <li>
                                      <div class="voice-switch">
                                          <span class="name">语音播报</span>
                                          <div class="switch">
                                              <onSwitch v-model="nodeObj.attribute.voiceAnnouncements" :true-value="1" :false-value="0" @on-change="$forceUpdate()" size="small"/>
                                          </div>
                                      </div>
                                  </li>
                                  <li v-if="nodeObj.attribute.voiceAnnouncements === 1">
                                      <div class="system-input-li">
                                          <Dropdown trigger="click" >
                                              <a href="javascript:">
                                                  {{ nodeObj.attribute.announcementsText }}
                                                  <Icon type="ios-arrow-down"></Icon>
                                              </a>
                                              <DropdownMenu slot="list">
                                                  <DropdownItem v-show="type.typeCode!=='nodeNameNodeContent'" :key="key" v-for="(type,key) in announcementsType"  @click.native="onAnnouncementsSwitch(type)">{{ type.typeName }}</DropdownItem>
                                              </DropdownMenu>
                                          </Dropdown>
                                      </div>
                                  </li>
                                  <li v-if="nodeObj.attribute.voiceAnnouncements === 1 && nodeObj.attribute.announcementsType === 'customContent'">
                                      <Input maxlength="50" show-word-limit :rows="4" type="textarea" v-model="nodeObj.attribute.customText" placeholder="请输入"/>
                                  </li>
                                  <li>
                                      <div class="title"><span class="label">按钮 </span></div>
                                      <div class="list">
                                          <div class="li" :key="'n'+key" v-for="(item,key) in nodeObj.attribute.nodeBtnList">
                                              <div class="color-item">
                                                  <Dropdown trigger="click" >
                                                      <a href="javascript:void(0)" class="code" :style="'background:'+item.color"></a>
                                                      <DropdownMenu slot="list">
                                                          <DropdownItem :key="'c'+index" v-for="(color,index) in colorList" :style="'background:'+color.color"></DropdownItem>
                                                      </DropdownMenu>
                                                  </Dropdown>
                                              </div>
                                              <div class="text-item regular-item">
                                                  <Dropdown @on-click="onText($event,key)">
                                                      <a href="javascript:void(0)">
                                                          <input v-model="item.buttonText" @blur="onButtonTextCheck(item,key)" type="text" class="item-input-regular" />
                                                          <Icon type="ios-arrow-down"></Icon>
                                                      </a>
                                                      <DropdownMenu slot="list">
                                                          <DropdownItem :name="btn.btnName" :disabled="onButtonTextRepeat(btn.btnName,key) || item.buttonText === btn.btnName" :key="'b'+j" v-for="(btn,j) in btnText">{{ btn.btnName }}</DropdownItem>
                                                      </DropdownMenu>
                                                  </Dropdown>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="info">
                                          <Icon custom="info-icon"></Icon>按钮文字只能是数字、汉字或者数字和汉字的组合
                                      </div>
                                  </li>
                              </ul>
                          </div>
                          <!--e: AI识别-->
                          <!--s: 环境监测-->
                          <div class="system-sub" v-if="nodeObj.nodeCode === NODE_NAME.NODE_ENVIRONMENTAL_MONITORING && !printVisible">
                              <ul>
                                  <li>
                                      <span class="label">步骤名称<font class="font">*</font></span>
                                      <Input v-model="nodeObj.nodeName" show-word-limit maxlength="20" placeholder="请输入" @on-keyup="onNodeName"/>
                                  </li>
                                  <!--                                  <li>-->
                                  <!--                                      <span class="label">标题</span>-->
                                  <!--                                      <Input v-model="nodeObj.attribute.nodeTitle" placeholder="请输入" @on-keyup="onNodeTitle"/>-->
                                  <!--                                  </li>-->
                                  <li>
                                      <div class="voice-switch">
                                          <span class="name">语音播报</span>
                                          <div class="switch">
                                              <onSwitch v-model="nodeObj.attribute.voiceAnnouncements" :true-value="1" :false-value="0" @on-change="$forceUpdate()" size="small"/>
                                          </div>
                                      </div>
                                  </li>
                                  <li v-if="nodeObj.attribute.voiceAnnouncements === 1">
                                      <div class="system-input-li">
                                          <Dropdown trigger="click" >
                                              <a href="javascript:">
                                                  {{ nodeObj.attribute.announcementsText }}
                                                  <Icon type="ios-arrow-down"></Icon>
                                              </a>
                                              <DropdownMenu slot="list">
                                                  <DropdownItem v-show="type.typeCode!=='nodeNameNodeContent'" :key="key" v-for="(type,key) in announcementsType"  @click.native="onAnnouncementsSwitch(type)">{{ type.typeName }}</DropdownItem>
                                              </DropdownMenu>
                                          </Dropdown>
                                      </div>
                                  </li>
                                  <li v-if="nodeObj.attribute.voiceAnnouncements === 1 && nodeObj.attribute.announcementsType === 'customContent'">
                                      <Input maxlength="50" show-word-limit :rows="4" type="textarea" v-model="nodeObj.attribute.customText" placeholder="请输入" @on-keyup="onAnnouncementsContent"/>
                                  </li>
                                  <li>
                                      <div class="title"><span class="label">按钮 </span></div>
                                      <div class="list">
                                          <div class="li" :key="'n'+key" v-for="(item,key) in nodeObj.attribute.nodeBtnList">
                                              <div class="color-item">
                                                  <Dropdown trigger="click" >
                                                      <a href="javascript:void(0)" class="code" :style="'background:'+item.color"></a>
                                                      <DropdownMenu slot="list">
                                                          <DropdownItem :key="'c'+index" v-for="(color,index) in colorList" :style="'background:'+color.color" @click.native="onColor(color,key)"></DropdownItem>
                                                      </DropdownMenu>
                                                  </Dropdown>
                                              </div>
                                              <div class="text-item regular-item">
                                                  <Dropdown @on-click="onText($event,key)">
                                                      <a href="javascript:void(0)">
                                                          <input v-model="item.buttonText" @blur="onButtonTextCheck(item,key)" type="text" class="item-input-regular" />
                                                          <Icon type="ios-arrow-down"></Icon>
                                                      </a>
                                                      <DropdownMenu slot="list">
                                                          <DropdownItem :name="btn.btnName" :disabled="onButtonTextRepeat(btn.btnName,key) || item.buttonText === btn.btnName" :key="'b'+j" v-for="(btn,j) in btnText">{{ btn.btnName }}</DropdownItem>
                                                      </DropdownMenu>
                                                  </Dropdown>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="info">
                                          <Icon custom="info-icon"></Icon>按钮文字只能是数字、汉字或者数字和汉字的组合
                                      </div>
                                  </li>
                              </ul>
                          </div>
                          <!--e: 环境监测-->
                          <!--s: 设备状态-->
                          <div class="system-sub" v-if="nodeObj.nodeCode === NODE_NAME.NODE_DEVICE_STATUS && !printVisible">
                              <ul>
                                  <li>
                                      <span class="label">步骤名称<font class="font">*</font></span>
                                      <Input v-model="nodeObj.nodeName" show-word-limit maxlength="20" placeholder="请输入"/>
                                  </li>
                                  <!--                                  <li>-->
                                  <!--                                      <span class="label">标题</span>-->
                                  <!--                                      <Input v-model="nodeObj.attribute.nodeTitle" placeholder="请输入" @on-keyup="onNodeTitle"/>-->
                                  <!--                                  </li>-->
                                  <li>
                                      <span class="label">设备名称<font class="font">*</font></span>
                                      <Input v-model="nodeObj.attribute.deviceName" show-word-limit :maxlength="100" type="textarea" :rows="4" placeholder="请输入"/>
                                  </li>
                                  <li>
                                      <span class="label">背景<font class="font">*</font></span>
                                      <div class="system-input-li">
                                          <Dropdown trigger="click" >
                                              <a href="javascript:">
                                                  {{ nodeObj.attribute.backgroundText }}
                                                  <Icon type="ios-arrow-down"></Icon>
                                              </a>
                                              <DropdownMenu slot="list">
                                                  <DropdownItem :key="key" v-for="(background,key) in backgroundType" :disabled="nodeObj.attribute.backgroundCode === background.typeCode">{{ background.typeName }}</DropdownItem>
                                              </DropdownMenu>
                                          </Dropdown>
                                      </div>
                                  </li>
                                  <li>
                                      <div class="voice-switch">
                                          <span class="name">语音播报</span>
                                          <div class="switch">
                                              <onSwitch v-model="nodeObj.attribute.voiceAnnouncements" :true-value="1" :false-value="0" @on-change="$forceUpdate()" size="small"/>
                                          </div>
                                      </div>
                                  </li>
                                  <li v-if="nodeObj.attribute.voiceAnnouncements === 1">
                                      <div class="system-input-li">
                                          <Dropdown trigger="click" >
                                              <a href="javascript:">
                                                  {{ nodeObj.attribute.announcementsText }}
                                                  <Icon type="ios-arrow-down"></Icon>
                                              </a>
                                              <DropdownMenu slot="list">
                                                  <DropdownItem v-show="type.typeCode!=='nodeNameNodeContent'" :key="key" v-for="(type,key) in announcementsType">{{ type.typeName }}</DropdownItem>
                                              </DropdownMenu>
                                          </Dropdown>
                                      </div>
                                  </li>
                                  <li v-if="nodeObj.attribute.voiceAnnouncements === 1 && nodeObj.attribute.announcementsType === 'customContent'">
                                      <Input maxlength="50" show-word-limit :rows="4" type="textarea" v-model="nodeObj.attribute.customText" placeholder="请输入"/>
                                  </li>
                                  <li>
                                      <div class="title"><span class="label">按钮 </span></div>
                                      <div class="list">
                                          <div class="li" :key="'n'+key" v-for="(item,key) in nodeObj.attribute.nodeBtnList">
                                              <div class="color-item">
                                                  <Dropdown trigger="click" >
                                                      <a href="javascript:void(0)" class="code" :style="'background:'+item.color"></a>
                                                      <DropdownMenu slot="list">
                                                          <DropdownItem :key="'c'+index" v-for="(color,index) in colorList" :style="'background:'+color.color"></DropdownItem>
                                                      </DropdownMenu>
                                                  </Dropdown>
                                              </div>
                                              <div class="text-item regular-item">
                                                  <Dropdown @on-click="onText($event,key)">
                                                      <a href="javascript:void(0)">
                                                          <input v-model="item.buttonText" @blur="onButtonTextCheck(item,key)" type="text" class="item-input-regular" />
                                                          <Icon type="ios-arrow-down"></Icon>
                                                      </a>
                                                      <DropdownMenu slot="list">
                                                          <DropdownItem :name="btn.btnName" :disabled="onButtonTextRepeat(btn.btnName,key) || item.buttonText === btn.btnName" :key="'b'+j" v-for="(btn,j) in btnText">{{ btn.btnName }}</DropdownItem>
                                                      </DropdownMenu>
                                                  </Dropdown>
                                              </div>
                                          </div>
                                        </div>
                                      <div class="info">
                                          <Icon custom="info-icon"></Icon>按钮文字只能是数字、汉字或者数字和汉字的组合
                                      </div>
                                  </li>
                              </ul>
                          </div>
                          <!--e: 设备状态-->
                          <!--s: 文本-->
                          <div class="system-sub" v-if="nodeObj.nodeCode === NODE_NAME.NODE_TEXT && !printVisible">
                              <ul>
                                  <li>
                                      <span class="label">节点名称<font class="font">*</font></span>
                                      <Input v-model="nodeObj.nodeName" placeholder="请输入" @on-keyup="onNodeName"/>
                                  </li>
<!--                                  <li>-->
<!--                                      <span class="label">标题</span>-->
<!--                                      <Input v-model="nodeObj.attribute.nodeTitle" placeholder="请输入" @on-keyup="onNodeTitle"/>-->
<!--                                  </li>-->
                                  <li>
                                      <span class="label">文本信息<font class="font">*</font></span>
                                      <Input v-model="nodeObj.attribute.nodeContent" placeholder="请输入" @on-keyup="onNodeContent"/>
                                  </li>
                                  <li>
                                      <span class="label">背景<font class="font">*</font></span>
                                      <div class="system-input-li">
                                          <Dropdown trigger="click" >
                                              <a href="javascript:">
                                                  {{ nodeObj.attribute.backgroundText }}
                                                  <Icon type="ios-arrow-down"></Icon>
                                              </a>
                                              <DropdownMenu slot="list">
                                                  <DropdownItem :key="key" v-for="(background,key) in backgroundType" :disabled="nodeObj.attribute.backgroundCode === background.typeCode">{{ background.typeName }}</DropdownItem>
                                              </DropdownMenu>
                                          </Dropdown>
                                      </div>
                                  </li>
                                  <li>
                                      <div class="title"><span>按钮 </span> <a href="javascript:" class="btn-add" @click="onAddButton"><Icon type="ios-add-circle" />添加按钮</a></div>
                                      <div class="list">
                                          <div class="li" :key="'n'+key" v-for="(item,key) in nodeObj.attribute.nodeBtnList">
                                              <div class="color-item">
                                                  <Dropdown trigger="click" >
                                                      <a href="javascript:void(0)" class="code" :style="'background:'+item.color"></a>
                                                      <DropdownMenu slot="list">
                                                          <DropdownItem :key="'c'+index" v-for="(color,index) in colorList" :style="'background:'+color.color" @click.native="onColor(color,key)"></DropdownItem>
                                                      </DropdownMenu>
                                                  </Dropdown>
                                              </div>
                                              <div class="text-item">
                                                  <Dropdown>
                                                      <a href="javascript:void(0)">
                                                          <input v-model="item.buttonText" @blur="onButtonTextCheck(item,key)" type="text" class="item-input" />
                                                          <Icon type="ios-arrow-down"></Icon>
                                                      </a>
                                                      <DropdownMenu slot="list">
                                                          <DropdownItem :key="'b'+j" v-for="(btn,j) in btnText" @click.native="onText(btn,key)">{{ btn.btnName }}</DropdownItem>
                                                      </DropdownMenu>
                                                  </Dropdown>
                                              </div>
                                              <div class="btn-touch">
                                                  <Dropdown trigger="click" >
                                                      <a href="javascript:void(0)" class="set-up">
                                                          <Tooltip content="点击触发" placement="top">
                                                              <Icon type="ios-cog" size="18" v-if="!item.triggerStatus"/>
                                                              <Icon type="ios-camera" size="18" v-if="item.triggerStatus == TRIGGER_NAME.TRIGGER_PHOTO"/>
                                                          </Tooltip>
                                                      </a>
                                                      <DropdownMenu slot="list">
                                                          <DropdownItem :key="'c'+index" v-for="(trigger,index) in triggerList"
                                                              :selected="trigger.value === item.triggerStatus"
                                                              :disabled="trigger.value === TRIGGER_NAME.TRIGGER_VIDEO"
                                                              @click.native="onTrigger(trigger,key)">
                                                              <Icon type="ios-camera" size="18" v-if="trigger.value === TRIGGER_NAME.TRIGGER_PHOTO"/>
                                                              <Icon type="ios-videocam" size="18" v-if="trigger.value === TRIGGER_NAME.TRIGGER_VIDEO"/>
                                                             {{ trigger.label }}
                                                          </DropdownItem>
                                                      </DropdownMenu>
                                                  </Dropdown>
                                              </div>
                                              <a href="javascript:" class="remove" @click="onRemoveBtn(key)"><Icon type="ios-trash-outline" /></a>
                                          </div>
                                      </div>
                                  </li>
                              </ul>
                          </div>
                          <!--e: 文本-->
                          <!--s: 图片-->
                          <div class="system-sub" v-if="nodeObj.nodeCode === NODE_NAME.NODE_IMAGE && !printVisible">
                              <ul>
                                  <li>
                                      <span class="label">节点名称<font class="font">*</font></span>
                                      <Input v-model="nodeObj.nodeName" placeholder="请输入" @on-keyup="onNodeName"/>
                                  </li>
<!--                                  <li>-->
<!--                                      <span class="label">标题</span>-->
<!--                                      <Input v-model="nodeObj.attribute.nodeTitle" placeholder="请输入" @on-keyup="onNodeTitle"/>-->
<!--                                  </li>-->
                                  <li>
                                      <span class="label">图片上传<font class="font">*</font></span>
                                      <div class="upload-box">
                                          <div class="upload" id="dropbox">
                                              <div class="box">
                                                  <input type="file" multiple="multiple" @change="onSignUpLoad($event)" ref="inputer" class="upload-input">
                                                  <Icon v-if="nodeObj.attribute.imageId ===''" type="ios-cloud-upload" size="52" style="color: #515a6e"></Icon>
                                                  <p v-if="nodeObj.attribute.imageId ===''" >将文件拖到此处，或<a href="javascript:" @click="choiceImg">点击上传</a></p>
                                                  <div class="upload-image-box" v-if="nodeObj.attribute.imageId !==''">
                                                      <img :src="nodeObj.attribute.imageUrl" class="image"/>
                                                      <div class="handle">
                                                          <div class="box">
                                                              <a href="javascript:" @click="onClearImage"><Icon type="ios-trash-outline" :size="25"/></a>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                          <span class="info">只能上传jpg/png文件，且不超过3M</span>
                                      </div>
                                  </li>
<!--                                  <li>-->
<!--                                      <span class="label">图片展示方式</span>-->
<!--                                      <Select v-model="nodeObj.attribute.imageMode" @on-change="onChangeImageMode">-->
<!--                                          <Option v-for="(item,key) in imageReveal" :value="item.attrKey" :key="key">{{ item.name }}</Option>-->
<!--                                      </Select>-->
<!--                                  </li>-->
<!--                                  <li>-->
<!--                                      <span class="label">文字说明</span>-->
<!--                                      <Input maxlength="50" show-word-limit type="textarea" v-model="nodeObj.attribute.nodeContent" placeholder="请输入" @on-keyup="onNodeContent"/>-->
<!--                                  </li>-->
                                  <li>
                                      <span class="label">作业内容<font class="font">*</font></span>
                                      <Input maxlength="100" show-word-limit type="textarea" :rows="8" v-model="nodeObj.attribute.nodeContent" placeholder="请输入" @on-keyup="onNodeContent"/>
                                  </li>
                                  <li>
                                      <div class="voice-switch">
                                          <span class="name">语音播报</span>
                                          <div class="switch">
                                              <onSwitch v-model="nodeObj.attribute.voiceAnnouncements" :true-value="1" :false-value="0" @on-change="$forceUpdate()" size="small"/>
                                          </div>
                                      </div>
                                  </li>
                                  <li v-if="nodeObj.attribute.voiceAnnouncements === 1">
                                      <div class="system-input-li">
                                          <Dropdown trigger="click" >
                                              <a href="javascript:">
                                                  {{ nodeObj.attribute.announcementsText }}
                                                  <Icon type="ios-arrow-down"></Icon>
                                              </a>
                                              <DropdownMenu slot="list">
                                                  <DropdownItem :key="key" v-for="(type,key) in announcementsType"  @click.native="onAnnouncementsSwitch(type)">{{ type.typeName }}</DropdownItem>
                                              </DropdownMenu>
                                          </Dropdown>
                                      </div>
                                  </li>
                                  <li v-if="nodeObj.attribute.voiceAnnouncements === 1 && nodeObj.attribute.announcementsType === 'customContent'">
                                      <Input maxlength="50" show-word-limit :rows="4" type="textarea" v-model="nodeObj.attribute.customText" placeholder="请输入" @on-keyup="onAnnouncementsContent"/>
                                  </li>
                                  <li>
                                      <span class="label">背景<font class="font">*</font></span>
                                      <div class="system-input-li">
                                          <Dropdown trigger="click" >
                                              <a href="javascript:">
                                                  {{ nodeObj.attribute.backgroundText }}
                                                  <Icon type="ios-arrow-down"></Icon>
                                              </a>
                                              <DropdownMenu slot="list">
                                                  <DropdownItem :key="key" v-for="(background,key) in backgroundType" :disabled="nodeObj.attribute.backgroundCode === background.typeCode">{{ background.typeName }}</DropdownItem>
                                              </DropdownMenu>
                                          </Dropdown>
                                      </div>
                                  </li>
                                  <li>
                                      <div class="title"><span>按钮 </span> <a href="javascript:" class="btn-add" @click="onAddButton"><Icon type="ios-add-circle" />添加按钮</a></div>
                                      <div class="list">
                                          <div class="li" :key="'n'+key" v-for="(item,key) in nodeObj.attribute.nodeBtnList">
                                              <div class="color-item">
                                                  <Dropdown trigger="click" >
                                                      <a href="javascript:void(0)" class="code" :style="'background:'+item.color"></a>
                                                      <DropdownMenu slot="list">
                                                          <DropdownItem :key="'c'+index" v-for="(color,index) in colorList" :style="'background:'+color.color" @click.native="onColor(color,key)"></DropdownItem>
                                                      </DropdownMenu>
                                                  </Dropdown>
                                              </div>
                                              <div class="text-item">
                                                  <span v-if="item.buttonText==='查看图片'">{{ item.buttonText }}</span>
                                                  <Dropdown v-if="item.buttonText!=='查看图片'">
                                                      <a href="javascript:void(0)">
                                                          <input v-model="item.buttonText" @blur="onButtonTextCheck(item,key)" type="text" class="item-input" />
                                                          <Icon type="ios-arrow-down"></Icon>
                                                      </a>
                                                      <DropdownMenu slot="list">
                                                          <DropdownItem :key="'b'+j" v-for="(btn,j) in btnText" @click.native="onText(btn,key)">{{ btn.btnName }}</DropdownItem>
                                                      </DropdownMenu>
                                                  </Dropdown>
                                              </div>
                                              <div class="btn-touch">
                                                  <Dropdown trigger="click" v-if="item.buttonText!=='查看图片'">
                                                      <a href="javascript:void(0)" class="set-up">
                                                          <Tooltip content="点击触发" placement="top">
                                                              <Icon type="ios-cog" size="18" v-if="!item.triggerStatus"/>
                                                              <Icon type="ios-camera" size="18" v-if="item.triggerStatus == TRIGGER_NAME.TRIGGER_PHOTO"/>
                                                          </Tooltip>
                                                      </a>
                                                      <DropdownMenu slot="list">
                                                          <DropdownItem :key="'c'+index" v-for="(trigger,index) in triggerList"
                                                              :selected="trigger.value === item.triggerStatus"
                                                              :disabled="trigger.value === TRIGGER_NAME.TRIGGER_VIDEO"
                                                              @click.native="onTrigger(trigger,key)">
                                                              <Icon type="ios-camera" size="18" v-if="trigger.value === TRIGGER_NAME.TRIGGER_PHOTO"/>
                                                              <Icon type="ios-videocam" size="18" v-if="trigger.value === TRIGGER_NAME.TRIGGER_VIDEO"/>
                                                              {{ trigger.label }}
                                                          </DropdownItem>
                                                      </DropdownMenu>
                                                  </Dropdown>
                                              </div>
                                              <a href="javascript:" class="remove" @click="onRemoveBtn(key)" v-if="nodeObj.nodeCode !== NODE_NAME.NODE_IMAGE || key !== 0"><Icon type="ios-trash-outline" /></a>
                                          </div>
                                      </div>
                                  </li>
                                  <li>
                                      <div class="voice-switch">
                                          <span class="name">定时跳转</span>
                                          <div class="switch">
                                              <onSwitch v-model="nodeObj.attribute.timedJumpStatus" :true-value="1" :false-value="0" @on-change="$forceUpdate()" size="small"/>
                                          </div>
                                      </div>
                                      <div class="info time-margin-top">
                                          <Icon custom="info-icon"></Icon>开启后，步骤会定时触发默认按钮
                                      </div>
                                      <div class="time" v-if="nodeObj.attribute.timedJumpStatus === 1">
                                          <span class="name">延迟时间（秒）</span>
                                          <div class="input-number">
                                              <Input-number :max="1000" :min="1" v-model="nodeObj.attribute.timedJumpNumber" @on-change="$forceUpdate()"></Input-number>
                                          </div>
                                      </div>
                                  </li>
                              </ul>
                          </div>
                          <!--e: 图片-->
                          <!--s: 批量操作-->
                          <div class="system-sub" v-if="nodeObj.nodeCode === NODE_NAME.NODE_BATCH_OPERATION && !printVisible">
                              <ul>
                                  <li>
                                      <span class="label">步骤名称<font class="font">*</font></span>
                                      <Input v-model="nodeObj.nodeName" placeholder="请输入" @on-keyup="onNodeName"/>
                                  </li>
                                  <!--                                  <li>-->
                                  <!--                                      <span class="label">标题</span>-->
                                  <!--                                      <Input v-model="nodeObj.attribute.nodeTitle" placeholder="请输入" @on-keyup="onNodeTitle"/>-->
                                  <!--                                  </li>-->
                                  <li>
                                      <span class="label">批量操作<font class="font">*</font></span>
                                      <div class="batch-box">

                                          <!--s: 暂无数据提示-->
                                          <div class="batch-spin" v-if="nodeObj.attribute.batchList.length === 0">
                                              <div class="box">
                                                  <Icon custom="batch-icon"></Icon>
                                                  <span class="text">暂无数据</span>
                                              </div>
                                          </div>
                                          <!--e: 暂无数据提示-->

                                          <!--s: 列表数据展示项-->
                                          <div class="batch-list" v-if="nodeObj.attribute.batchList.length !== 0">
                                              <span class="text-info" :key="key" v-for="(batchList,key) in nodeObj.attribute.batchList">{{ batchList.textInfo }}</span>
                                          </div>
                                          <!--e: 列表数据展示项-->

                                      </div>
                                      <a href="javascript:" class="batch-btn">配置批量操作</a>
                                  </li>
                              </ul>
                          </div>
                          <!--e: 批量操作-->
                          <!--s: 批量输入-->
                          <div class="system-sub" v-if="nodeObj.nodeCode === NODE_NAME.NODE_BATCH_INPUT && !printVisible">
                              <ul>
                                  <li>
                                      <span class="label">步骤名称<font class="font">*</font></span>
                                      <Input v-model="nodeObj.nodeName" placeholder="请输入" @on-keyup="onNodeName"/>
                                  </li>
                                  <li>
                                      <span class="label">批量输入<font class="font">*</font></span>
                                      <div class="batch-box">

                                          <!--s: 暂无数据提示-->
                                          <div class="batch-spin" v-if="nodeObj.attribute.batchInputList.length === 0">
                                              <div class="box">
                                                  <Icon custom="batch-icon"></Icon>
                                                  <span class="text">暂无数据</span>
                                              </div>
                                          </div>
                                          <!--e: 暂无数据提示-->

                                          <!--s: 列表数据展示项-->
                                          <div class="batch-list" v-if="nodeObj.attribute.batchInputList.length !== 0">
                                              <span class="text-info" :key="key" v-for="(batchInput,key) in nodeObj.attribute.batchInputList">{{ batchInput.textInfo }}</span>
                                          </div>
                                          <!--e: 列表数据展示项-->

                                      </div>
                                      <a href="javascript:" class="batch-btn">配置批量输入</a>
                                  </li>
                              </ul>
                          </div>
                          <!--e: 批量输入-->
                      </div>
                  </TabPane>
                  <TabPane label="全局设置">
                      <div class="system-content" :class="JSON.stringify(flowReturnObj)!='{}' && flowReturnObj.status === 1?'spin':''">
                          <div class="system-settings">
                              <ul>
                                  <li>
                                      <span class="label">全程录像</span>
                                      <div class="switch">
                                          <onSwitch v-model="configParams.videotapeStatus" :true-value="1" :false-value="0" size="small"/>
                                      </div>
                                  </li>
                                  <li>
                                      <span class="label">是否小窗口预览</span>
                                      <div class="switch">
                                          <onSwitch v-model="configParams.smallWindowPreview" :true-value="1" :false-value="0" size="small"/>
                                      </div>
                                  </li>
                                  <li>
                                      <span class="label">快捷反馈</span>
                                      <div class="switch">
                                          <onSwitch v-model="configParams.quickFeedback" :true-value="1" :false-value="0" size="small"/>
                                      </div>
                                  </li>
                                  <li class="switch-group" v-if="configParams.quickFeedback === 1">
                                      <CheckboxGroup v-model="configParams.quickFeedbackList">
                                          <Checkbox :label="quickList.typeCode" :disabled="configParams.quickFeedback === 0" :key="key" v-for="(quickList,key) in quickFeedbackArray">{{ quickList.typeName }}</Checkbox>
                                      </CheckboxGroup>
                                  </li>
                              </ul>
                          </div>
                      </div>
                  </TabPane>
              </Tabs>
              <Spin fix v-if="systemLoading"></Spin>
          </div>
          <!--e: config system-->

      </div>
      <!--e: body-->
      <Spin fix v-if="configLoading"></Spin>
      <Modal
          v-model="taskModalVisible"
          title='创建任务'
          footer-hide>
          <div class="device-modal">
              <span class="label">任务名称：</span>
              <Input v-model="configParams.tasksName" placeholder="请输入" style="width: 300px" />
          </div>
          <div class="device-modal">
              <span class="label">引用流程：</span>
              <Input v-model="flowReturnObj.flowName" disabled placeholder="请输入" style="width: 300px" />
          </div>
          <div class="device-footer task-btn">
              <Button type="primary" :loading="editLoading" @click="onSubmitCheck(1)">立即发布</Button>
              <Button type="primary" :loading="editLoading" ghost @click="onSubmitCheck(0)">稍后发布</Button>
              <Button type="text" @click="taskModalVisible = false">取 消</Button>
          </div>
      </Modal>
  </div>
</template>
<script>
import ProcessConfig from './config'
export default ProcessConfig
</script>
<style lang="less">
@import "config";
</style>
