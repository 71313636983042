import { Addon,} from '@antv/x6';
// import { NODE_NAME } from '@/service/workflow-service'
// 拖拽生成四边形
export const startDragToGraph  = (graph,type,e) =>{
  // const nodeStart = `
  //         <div class="node-box">
  //             <span class="node-btn-start">${type.name}</span>
  //         </div>`
  // const nodeEnd = `
  //         <div class="node-box">
  //             <span class="node-btn-end">${type.name}</span>
  //         </div>`
  // const nodeAction = `
  //         <div class="node-box">
  //             <span class="${type.icon}" style="background-color:#fcce51"></span>
  //             <span class="node-text">${type.name}</span>
  //         </div>`
  // const nodeElement = `
  //         <div class="node-box">
  //             <span class="${type.icon}" style="background-color:#fda173"></span>
  //             <span class="node-text">${type.name}</span>
  //         </div>`
  const node = graph.createNode({
      width: type.width,  //节点的宽度
      height: 35,   //节点的高度
      nodeCode:type.nodeCode,
      nodeName:type.name,
      attribute:{
        nodeTitle:'',
        nodeContent:'',
        nodeBtnList:[],
        imageMode:'center', //图片展示方式
        imageId:'', //图片id
        imageUrl:'',//图片url
      },
      attrs: {
        label: {
          text: type.name,
          fill: type.color,
          fontSize: 14,
          textWrap: {
            width: -10 ,
            height: -10,
            ellipsis: true,
          },
        },
        body: {
          stroke: type.borderColor,
          strokeWidth: 2,
          fill: type.background,
          rx: 6, // 圆角矩形
          ry: 6,
        },
      },
      // shape: 'html',
      // html() { //${type.name}
      //   switch (type.nodeCode){
      //     case NODE_NAME.NODE_START:
      //       return nodeStart;
      //     case NODE_NAME.NODE_END:
      //       return nodeEnd;
      //     case NODE_NAME.NODE_PHOTO:
      //       return nodeAction
      //     case NODE_NAME.NODE_IMAGE:
      //       return nodeElement
      //     case NODE_NAME.NODE_TEXT:
      //       return nodeElement
      //   }
      // },
      ports: ports,
    })
  const dnd = new Addon.Dnd({target:graph})
  dnd.start(node,e)
}
const ports = {
  groups: {
    // 输入链接桩群组定义
    top: {
      position: 'top',
      attrs: {
        circle: {
          r: 4,
          magnet: true,
          stroke: '#2D8CF0',
          strokeWidth: 2,
          fill: '#fff',
        },
      },
    },
    // 输出链接桩群组定义
    bottom: {
      position: 'bottom',
      attrs: {
        circle: {
          r: 4,
          magnet: true,
          stroke: '#2D8CF0',
          strokeWidth: 2,
          fill: '#fff',
        },
      },
    },
    left: {
      position: 'left',
      attrs: {
        circle: {
          r: 4,
          magnet: true,
          stroke: '#2D8CF0',
          strokeWidth: 2,
          fill: '#fff',
        },
      },
    },
    right: {
      position: 'right',
      attrs: {
        circle: {
          r: 4,
          magnet: true,
          stroke: '#2D8CF0',
          strokeWidth: 2,
          fill: '#fff',
        },
      },
    },
  },
  items: [
    {
      id: 'port1',
      group: 'top',
    },
    {
      id: 'port2',
      group: 'bottom',
    },
    {
      id: 'port3',
      group: 'left',
    },
    {
      id: 'port4',
      group: 'right',
    }
  ],
}
