/**
 * 工作流
 * @type {{}}
 */
import Vue from "vue";
import { Input, Icon,Tooltip,Message,Switch, Select,Modal, Option,Button,Tabs,TabPane,Dropdown,DropdownMenu,DropdownItem,Upload,Spin,Drawer,Table,Poptip,InputNumber,CheckboxGroup,Checkbox } from "view-design";
Vue.component('Input', Input);
Vue.component('Icon', Icon);
Vue.component('Tooltip', Tooltip);
Vue.component('Select', Select);
Vue.component('Option', Option);
Vue.component('Button', Button);
Vue.component('Tabs', Tabs);
Vue.component('TabPane', TabPane);
Vue.component('onSwitch', Switch);
Vue.component('Dropdown', Dropdown);
Vue.component('DropdownMenu', DropdownMenu);
Vue.component('DropdownItem', DropdownItem);
Vue.component('Upload', Upload);
Vue.component('Spin', Spin);
Vue.component('Modal', Modal);
Vue.component('Drawer', Drawer);
Vue.component('Table', Table);
Vue.component('Poptip', Poptip);
Vue.component('InputNumber', InputNumber);
Vue.component('CheckboxGroup', CheckboxGroup);
Vue.component('Checkbox', Checkbox);
import {
    configList,
    NODE_NAME,
    triggerList,
    TRIGGER_NAME,
    colorList,
    btnText,
    imageReveal,
    addWorkflowImage,
    inputType,
    unitType,
    DEFAULT_BUTTON_NAME,
    quickFeedbackArray,
    editWorkFlowService,
    getWorkFlowInfoService,
    updateWorkFlowStatusService,
    editTaskListService,
    addUploadDataService,
    getImageUrlService, endSettingsType,announcementsType,backgroundType
} from "@/service/workflow-service";
import { Graph } from '@antv/x6'
import { startDragToGraph } from '@/utils/graph'
import { mapState } from "vuex";

const ProcessConfig = {
    name:'ProcessConfig',
    computed: {
        ...mapState({
            flowId: state => state.system.flowId, //流程id
        }),
    },
    data() {
        return {
            configLoading:false,
            systemLoading:false, //右侧属性设置loading
            taskModalVisible:false, //任务弹框可显示
            editLoading:false,
            addWorkflowImage:addWorkflowImage,
            configList:configList, //工作流菜单
            triggerList:triggerList, //触发数据
            NODE_NAME:NODE_NAME, //节点code
            TRIGGER_NAME:TRIGGER_NAME, //触发字典名称
            colorList:colorList, //颜色列表
            btnText:btnText,// 按钮文字列表
            imageReveal:imageReveal,// 图片展示方式
            configNameVisible:false, //设置工作流边框可显示
            printVisible:true,
            thumbVisible:false, //缩略图可显示
            lineVisible:false,
            lineSelectList:[],
            sourceId:'', //来源节点的id
            lineId:'', //当前线条的id
            sourceBtnList:[], //来源的按钮组
            startLineVisible:false,
            defaultButtonName:DEFAULT_BUTTON_NAME,
            announcementsType:announcementsType,
            backgroundType:backgroundType,
            endSettingsType:endSettingsType, //结束设置类型
            nodeObj:{}, //节点内容
            flowReturnObj:{}, //保存流程数据原
            imageObj:{}, //图片参数对象
            inputType:inputType, //输入类型
            unitType:unitType, //单位
            quickFeedbackArray:quickFeedbackArray, //快捷反馈数据列表
            triggerARStatus:false, //触发按钮控制状态
            graph:null,
            shape:null,
            addon:null,
            zoom:1,
            btnList:{
                colorId:1,
                color:'#eccf3e',
                buttonText:'正常',
                buttonId:1,
                triggerStatus:'',
                buttonStatus:'',
            },
            configParams:{
                tasksName:'', //任务名称
                configName:'', //设置工作流名称
                videotapeStatus:0, //全程录像设置
                smallWindowPreview:0, //是否小窗口预览
                quickFeedback:1, //快捷反馈
                quickFeedbackList:['photograph','soundRecording'], //快捷反馈内容
            },
        }
    },
    created() {
        if(this.flowId !== ''){
            this.onWorkFlowInfo().then()
        }
    },
    mounted() {
        this.onInit();
    },
    methods: {
        /**
         * 初始化流程图
         */
        onInit() {
            const container = document.getElementById('container')
            const graph = new Graph({
                grid: {
                    size:10,
                    visible:true,
                    type:'mesh',
                    args:{
                        color:'#e9ebee',
                        thickness:1,
                        factor:10
                    },
                },
                selecting: {
                    enabled: true,
                    // rubberband: true,
                    className: 'container-selecting',
                    showNodeSelectionBox: true,
                    showEdgeSelectionBox:true,
                },
                snapline: true,
                keyboard: true,
                clipboard: true,
                container:container,
                autoResize:true,
                scroller:{
                    enabled:true,
                    pannable:true
                },
                connecting: {
                    snap: true,     //当 snap 设置为 true 时连线的过程中距离节点或者连接桩 50px 时会触发自动吸附
                    allowBlank: false,      //是否允许连接到画布空白位置的点
                    allowLoop: false,       //是否允许创建循环连线，即边的起始节点和终止节点为同一节点
                    allowMulti:false,
                    highlight: true,
                    // sourceAnchor: 'center',     //当连接到节点时，通过 sourceAnchor 来指定源节点的锚点。
                    // targetAnchor: 'center',     //当连接到节点时，通过 targetAnchor 来指定目标节点的锚点。
                    // connectionPoint: 'anchor',
                    // 设置连接线的样式
                    router: {
                        // 样式名
                        name: 'manhattan',
                        args: {
                            startDirections: ['top', 'right', 'bottom', 'left'],    //支持从哪些方向开始路由。
                            endDirections: ['top', 'right', 'bottom', 'left'],      //支持从哪些方向结束路由。
                        },
                    },
                    // 连接线
                    createEdge(){
                        return graph.createEdge({
                            attribute:{
                                selectedId:'', //选中的id
                            },
                            attrs: {
                                line: {
                                    // stroke: '#2d8cf0',
                                    stroke: '#A2B1C3',
                                    strokeWidth: 2,
                                    targetMarker: {
                                        name: 'block',
                                        args: {
                                            size: '6',
                                        },
                                    },
                                },
                                selecting: {
                                    enabled: true,
                                    rubberband: true,
                                    showNodeSelectionBox: true,
                                },
                            },
                        })
                    },
                    // 验证连接
                    validateConnection({
                                           targetCell,
                                           targetView,
                                           sourceMagnet,
                                           targetMagnet,
                                       }) {
                        // 只能从输出链接桩创建连接
                        if (!sourceMagnet) {
                            return false
                        }
                        // 只能连接到输入链接桩
                        if (!targetMagnet) {
                            return false
                        }
                        if(targetCell.store.data.nodeCode === NODE_NAME.NODE_START){
                            return false
                        }
                        // 判断目标链接桩是否可连接
                        const portId = targetMagnet.getAttribute('port');
                        const node = targetView.cell
                        const port = node.getPort(portId)
                        if (port && port.connected) {
                            return false
                        }
                        return true
                    },
                    validateEdge({edge}){
                        let edgesLength = graph.getConnectedEdges(edge.store.data.source.cell,{ outgoing: true })
                        let sourceData = graph.getCellById(edge.store.data.source.cell).store.data
                        if(sourceData.nodeCode === NODE_NAME.NODE_START || sourceData.nodeCode === NODE_NAME.NODE_PHOTO || sourceData.nodeCode === NODE_NAME.NODE_VOICE_INPUT || sourceData.nodeCode === NODE_NAME.NODE_BATCH_OPERATION || sourceData.nodeCode === NODE_NAME.NODE_BATCH_INPUT){
                            if(edgesLength.length>1){
                                Message.warning('该模块最多只能拉出1条线！')
                                return false
                            }
                        }
                        if(sourceData.nodeCode === NODE_NAME.NODE_END && edgesLength.length>0){
                            return false
                        }
                        let regularNumber = 0
                        for(let i in sourceData.attribute.nodeBtnList){
                            if(!sourceData.attribute.nodeBtnList[i].regularButton){
                                regularNumber+=1
                            }
                        }
                        if(edgesLength.length>regularNumber && sourceData.nodeCode !== NODE_NAME.NODE_START){
                            let resultRegular = sourceData.attribute.nodeBtnList.some(item =>{
                                if(item.regularButton){
                                    return true
                                }
                            })
                            Message.warning('该模块最多只能拉出'+(resultRegular?regularNumber:sourceData.attribute.nodeBtnList.length)+'条线！')
                            return false
                        }

                        let sourceBtnStatus
                        for(let i in sourceData.attribute.nodeBtnList){
                            if(sourceData.attribute.nodeBtnList[i].lineId === ''){
                                sourceBtnStatus = true
                            }
                        }
                        if(!sourceBtnStatus && sourceData.nodeCode !== NODE_NAME.NODE_START){
                            Message.warning('按钮已被全部关联，无法在拉取线条！')
                            return false
                        }
                        return true
                    }
                }
            });
            this.graph = graph
            //delete
            graph.bindKey('backspace', () => {
                if(this.flowReturnObj.status === 1){ return }
                const cells = graph.getSelectedCells()
                if (cells.length) {
                    const Id = cells[0].id
                    const cell = graph.getCellById(Id)
                    if(cell.isEdge()){
                        let btnList = this.graph.getCellById(this.sourceId).store.data.attribute.nodeBtnList
                        for(let i in btnList){
                            if(this.lineId === btnList[i].lineId){
                                btnList[i].lineId = ''
                            }
                        }
                        this.lineVisible = false
                        this.printVisible = true
                        this.startLineVisible = false
                        graph.removeCells(cells)
                        return
                    }
                    Modal.confirm({
                        title: '确认要删除该步骤吗?',
                        content: '删除后不可回退，配置的信息将会丢失。',
                        okText:'确定',
                        cancelText:'取消',
                        onOk:()=>{
                            let incomingLength = this.graph.getConnectedEdges(Id,{ incoming: true }) //返回输入的边
                            for(let i in incomingLength){
                                let btnList = this.graph.getCellById(incomingLength[i].source.cell).store.data.attribute.nodeBtnList
                                for(let j in btnList){
                                    if(incomingLength[i].id === btnList[j].lineId){
                                        btnList[j].lineId = ''
                                    }
                                }
                            }
                            graph.removeCells(cells)
                            this.printVisible = true
                            this.thumbVisible = false
                            this.nodeObj = {}
                        },
                    });
                }
            })
            graph.bindKey('delete',() =>{
                if(this.flowReturnObj.status === 1){ return }
                const cells = graph.getSelectedCells()
                if (cells.length) {
                    const Id = cells[0].id
                    const cell = graph.getCellById(Id)
                    if(cell.isEdge()){
                        let btnList = this.graph.getCellById(this.sourceId).store.data.attribute.nodeBtnList
                        for(let i in btnList){
                            if(this.lineId === btnList[i].lineId){
                                btnList[i].lineId = ''
                            }
                        }
                        this.$forceUpdate()
                        this.lineVisible = false
                        this.printVisible = true
                        this.startLineVisible = false
                        graph.removeCells(cells)
                        return
                    }
                    Modal.confirm({
                        title: '确认要删除该步骤吗?',
                        content: '删除后不可回退，配置的信息将会丢失。',
                        okText:'确定',
                        cancelText:'取消',
                        onOk:()=>{
                            let incomingLength = this.graph.getConnectedEdges(Id,{ incoming: true }) //返回输入的边
                            for(let i in incomingLength){
                                let btnList = this.graph.getCellById(incomingLength[i].source.cell).store.data.attribute.nodeBtnList
                                for(let j in btnList){
                                    if(incomingLength[i].id === btnList[j].lineId){
                                        btnList[j].lineId = ''
                                    }
                                }
                            }
                            graph.removeCells(cells)
                            this.printVisible = true
                            this.thumbVisible = false
                            this.nodeObj = {}
                        },
                    });
                }
            })
            /**
             * 节点被添加到画布中触发
             */
            graph.on('node:added',(node) =>{
                graph.resetSelection(node.cell)
            })
            /**
             * 监听连线完成后获取线条数据
             */
            graph.on('edge:connected', ({ isNew, edge }) => {
                if (isNew) {
                    graph.resetSelection(edge)
                }
            })
            /**
             * 节点被选中时触发
             */
            graph.on('node:selected', (node) => {
                this.nodeObj = {}
                this.printVisible = false
                this.lineVisible = false
                this.nodeObj = node.node.store.data
                this.nodeObj.nodeName = node.node.store.data.attrs.label.text
                this.thumbVisible = true
                this.triggerARStatus = false
                this.startLineVisible = false
            })
            /**
             * 连接线被选中时触发
             */
            graph.on('edge:selected',(cell) =>{
                if(!cell.cell.target.cell){
                    return
                }
                cell.cell.toFront()
                this.lineSelectList = []
                let cellObj = cell.cell.store.data
                this.sourceId = cellObj.source.cell
                this.lineId = cell.cell.id
                let data = this.graph.getCellById(this.sourceId).store.data
                this.sourceBtnList = data.attribute.nodeBtnList
                if(data.id === cellObj.source.cell){
                    if(data.nodeCode === NODE_NAME.NODE_START){
                        this.startLineVisible = true
                        this.lineVisible = false
                    }else{
                        this.startLineVisible = false
                        this.lineVisible = true
                    }
                }
                let regularBtnCount = 0
                for(let i in this.sourceBtnList){
                    if(!this.sourceBtnList[i].regularButton){
                        regularBtnCount+=1
                    }
                }
                if(regularBtnCount === 1){
                    for(let i in this.sourceBtnList){
                        if(!this.sourceBtnList[i].regularButton){
                            this.sourceBtnList[i].lineId = this.lineId
                        }
                    }
                }
                for(let i in this.sourceBtnList){
                    if(this.sourceBtnList[i].lineId !==''){
                        this.$nextTick(()=>{
                            this.lineSelectList.push(this.sourceBtnList[i].id)
                        })
                    }
                }

                this.nodeObj = {}
                this.printVisible = false
            })
            /**
             * 点击空白处
             */
            graph.on('blank:click',()=>{
                this.nodeObj = {}
                this.lineVisible = false
                this.printVisible = true
                this.startLineVisible = false
            })
        },
        /**
         * 返回
         */
        onBack() {
            this.$router.go(-1)
        },
        /**
         * 放大画布
         */
        onThumbEnlarge() {
            if(this.zoom > 1.5){
                Message.warning('画布已经放大到最大值！')
                return
            }
            this.zoom += 0.1
            this.graph.zoomTo(this.zoom)
        },
        /**
         * 缩小画布
         */
        onThumbNarrow() {
            if(this.zoom < 0.6){
                Message.warning('画布已经缩放到最小值！')
                return
            }
            this.zoom -= 0.1
            this.graph.zoomTo(this.zoom)
        },
        /**
         * 生成节点
         * @param type
         * @param e
         */
        startDrag(type, e) {
            startDragToGraph(this.graph, type, e);
        },
        /**
         * 节点开始结束判断
         * @param name
         * @returns {*}
         */
        onNodeStartEndCheck(name) {
            return this.graph.toJSON().cells.some(function(item) {
                 if (item.nodeCode === name) {
                     return true;
                 }else{
                     return false
                 }
            })
        },
        /**
         * 发布
         */
        onRelease(status) {
            if(JSON.stringify(this.flowReturnObj)=='{}'){
                Message.warning('请先保存工作流！')
                return
            }
            this.onUpdateWorkFlowStatus(status).then()
        },
        /**
         * 验证按钮文字是否相同
         * @param text
         * @param key
         * @returns {boolean}
         */
        onButtonTextRepeat(text,key){
            const cells = this.graph.getSelectedCells()
            let buttonList = cells[0].store.data.attribute.nodeBtnList
            let status = false
            for(let i in buttonList){
                if((buttonList[i].buttonText === text && i!=key) || text==='上一步'){
                    status = true
                }
            }
            return status
        },
        /**
         * 提交验证
         */
        onSubmitCheck(status) {
            if(this.configParams.tasksName === ''){
                Message.error('请填写任务名称！');
                return;
            }
            this.onEditTaskList(status).then()
        },
        /**
         * 点击上传
         */
        choiceImg(){
            this.$refs.inputer.dispatchEvent(new MouseEvent('click'))
        },
        /**
         * 验证图片格式/拉取参数
         * @param e
         * @returns {Promise<void>}
         */
        async onSignUpLoad(e) {
            let files = e.target.files[0]
            if(files){
                if(files.type !== 'image/jpeg' && files.type !== 'image/png'){
                    Message.warning('文件格式错误，只能上传jpg/png文件！')
                    return;
                }
                if(files.size > 3*1024*1024){
                    Message.warning('只能上传3M以内的图片！')
                    return;
                }
                this.onUploadDataService(files).then()
            }
        },
        /**
         * 节点验证
         * @param obj
         */
        onNodeVisible(obj,code){
             return obj.filter((e)=>e.nodeCode === code).length
        },
        /**
         * 节点必填项验证
         */
        onNodeCheck(data) {
            let name
            for(let i in data){
                if(data[i].nodeCode === NODE_NAME.NODE_TEXT){
                    if(data[i].attribute.nodeContent == ''){
                        name = 'Text'
                    }
                }
                if(data[i].nodeCode === NODE_NAME.NODE_IMAGE){
                    if(data[i].attribute.imageId === ''){
                        name = 'Image'
                    }
                }
            }
            return name
        },
        /**
         * 保存导出json
         */
        onSave() {
            if(this.graph.toJSON().cells.length === 0){
                Message.warning('请配置工作流！')
                return;
            }

            if(!this.onNodeStartEndCheck(NODE_NAME.NODE_START) || !this.onNodeStartEndCheck(NODE_NAME.NODE_END)){
                Message.warning('一个工作流必须要有一个开始和一个结束节点！')
                return;
            }

            let obj = []
            for(let i in this.graph.toJSON().cells){
                if(this.graph.toJSON().cells[i].shape === 'rect'){
                    obj.push(this.graph.toJSON().cells[i])
                }
            }
            if(this.onNodeVisible(obj,NODE_NAME.NODE_START) >1 || this.onNodeVisible(obj,NODE_NAME.NODE_END)>1){
                Message.warning('工作流只能有一个开始和一个结束节点！')
                return
            }

            if(this.graph.toJSON().cells.length <3){
                Message.warning('一个工作流必须要有至少一个UI控制节点！')
                return
            }

            if(this.configParams.configName === ''){
                Message.warning('请先填写工作流名称！')
                return;
            }
            if(this.onNodeCheck(this.graph.toJSON().cells) === 'Text'){
                Message.warning('您有节点未填写文本信息！')
                return
            }

            if(this.onNodeCheck(this.graph.toJSON().cells) === 'Image'){
                Message.warning('您有节点未上传图片！')
                return
            }
            this.onEditWorkFlow(this.graph.toJSON()).then()

        },
        onNodeName(text){
            const cells = this.graph.getSelectedCells()
            cells[0].store.data.attrs.label.text = text.target._value
            cells[0].attr('text/text', text.target._value)
            if(cells[0].store.data.attrs.label.text === ''){
                switch (this.nodeObj.nodeCode){
                    case NODE_NAME.NODE_TEXT:
                        cells[0].store.data.attrs.label.text = '文本'
                        cells[0].attr('text/text', '文本')
                    break;
                    case NODE_NAME.NODE_PHOTO:
                        cells[0].store.data.attrs.label.text = '拍照'
                        cells[0].attr('text/text', '拍照')
                        break;
                    case NODE_NAME.NODE_IMAGE:
                        cells[0].store.data.attrs.label.text = '图片'
                        cells[0].attr('text/text', '图片')
                        break;
                }
            }
        },
        onNodeTitle(title) {
            const cells = this.graph.getSelectedCells()
            cells[0].store.data.attribute.nodeTitle = title.target._value
        },
        onNodeContent(content) {
            const cells = this.graph.getSelectedCells()
            cells[0].store.data.attribute.nodeContent = content.target._value
        },
        onAddButton() {
            const cells = this.graph.getSelectedCells()
            if( cells[0].store.data.attribute.nodeBtnList.length>=5){
                Message.warning('您最多可以添加5个按钮！');
                return
            }
            cells[0].store.data.attribute.nodeBtnList.push({
                color:'#eccf3e',
                buttonText:'正常',
            })
        },
        onButtonTextCheck(item,key){
            if(item.buttonText.length > 5){
                this.nodeObj.attribute.nodeBtnList[key].buttonText = item.buttonText.slice(0,5)
                Message.warning('最多只能输入五个字符')
            }
            if(item.buttonText.length === 0){
                this.nodeObj.attribute.nodeBtnList[key].buttonText = '正常'
            }
        },
        onColor(color,key){
            const cells = this.graph.getSelectedCells()
            cells[0].store.data.attribute.nodeBtnList[key].color = color.color
        },
        onText(text,key){
            const cells = this.graph.getSelectedCells()
            cells[0].store.data.attribute.nodeBtnList[key].buttonText = text.btnName
            this.$forceUpdate()
        },
        onRemoveBtn(key){
            const cells = this.graph.getSelectedCells()
            cells[0].store.data.attribute.nodeBtnList.splice(key,1)
        },
        onTrigger(trigger,key) {
            if(trigger.value === TRIGGER_NAME.TRIGGER_VIDEO){
                return
            }
            const cells = this.graph.getSelectedCells()
            if(trigger.value ===  cells[0].store.data.attribute.nodeBtnList[key].triggerStatus ){
                cells[0].store.data.attribute.nodeBtnList[key].triggerStatus = ''
            }else{
                cells[0].store.data.attribute.nodeBtnList[key].triggerStatus = trigger.value
            }
            this.$forceUpdate()
        },
        onChangeImageMode(mode){
            const cells = this.graph.getSelectedCells()
            cells[0].store.data.attribute.imageMode = mode
        },
        /**
         * 清空图片
         */
        onClearImage() {
            const cells = this.graph.getSelectedCells()
            cells[0].store.data.attribute.imageId = ''
            cells[0].store.data.attribute.imageUrl = ''
            this.nodeObj.attribute.imageId = ''
            this.nodeObj.attribute.imageUrl = ''
            this.$refs.inputer.value = ''
            this.$forceUpdate()
        },
        /**
         * 编辑工作流
         */
        async onEditWorkFlow(detail) {
            try {
                let params = {
                    id:this.flowId,
                    flowName:this.configParams.configName,
                    videotape:this.configParams.videotapeStatus,
                    detail:JSON.stringify(detail)
                }
                const { code,data } = await editWorkFlowService(params)
                if(code === 'SUCCESS'){
                    Message.success('保存成功！')
                    this.flowReturnObj = data
                }else{
                    Message.error('请求异常，请联系管理员！')
                }

            }catch (error){
                console.log(error)
            }
        },
        /**
         * 获取工作流信息
         */
        async onWorkFlowInfo() {
            try {
                this.configLoading = true
                let params = {
                    id:this.flowId,
                }
                const { code,data } = await getWorkFlowInfoService(params)
                if(code === 'SUCCESS'){
                    this.configParams.videotapeStatus = data.videotape
                    this.configParams.configName = data.flowName
                    this.configParams.smallWindowPreview = data.smallWindowPreview
                    this.configParams.quickFeedback = data.quickFeedback
                    this.configParams.quickFeedbackList = data.quickFeedbackList
                    this.graph.fromJSON(JSON.parse(data.detail))
                    this.flowReturnObj = data

                }else{
                    Message.error('请求异常，请联系管理员！')
                }
                this.configLoading = false
            }catch (error){
                this.configLoading = false
                console.log(error)
            }
        },
        /**
         * 工作流更新发布状态
         */
        async onUpdateWorkFlowStatus(status) {
            try {
                let params = {
                    id:this.flowReturnObj.id,
                    status:status
                }
                const { code } = await updateWorkFlowStatusService(params)
                if(code === 'SUCCESS'){
                    if(status === 1 ){
                        Message.success('发布成功！')
                    }
                    if(status === 0 ){
                        Message.success('撤回成功！')
                    }
                    this.flowReturnObj.status = status
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
            }catch (error){
                console.log(error)
            }
        },
        /**
         * 编辑任务
         */
        async onEditTaskList(status) {
            try {
                this.editLoading = true
                let params = {
                    id:'',
                    tasksName:this.configParams.tasksName,
                    flowId:this.flowReturnObj.id,
                    status:status,
                }
                const { code } = await editTaskListService(params)
                if(code === 'SUCCESS'){
                    this.$router.push('/workflow/task')
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
                this.editLoading = false
            }catch (error){
                this.editLoading = false
                console.log(error)
            }
        },
        /**
         * 拉取资源图片
         * @returns {Promise<void>}
         */
        async onImageUrl(id) {
            try {
                this.systemLoading = true
                let params = {
                    resourceName:id,
                }
                const {code,data} = await getImageUrlService(params);
                if(code === 'SUCCESS'){
                    const cells = this.graph.getSelectedCells()
                    cells[0].store.data.attribute.imageUrl = data.url
                    this.$forceUpdate()
                }else{
                    Message.error('拉取上传参数出错，请稍后再试！')
                }
                this.systemLoading = false
            }catch (error) {
                this.systemLoading = false
                console.log(error)
            }
        },
        /**
         * 添加资料文件
         */
        async onUploadDataService(files) {
            try {
                this.configLoading = true
                let params = new FormData()
                params.append('files', files)

                const { code,data } = await addUploadDataService(params)
                if (code === 'SUCCESS') {
                    const cells = this.graph.getSelectedCells()
                    cells[0].store.data.attribute.imageId = data.id
                    cells[0].store.data.attribute.imageUrl = data.url
                    this.$forceUpdate()
                } else {
                    Message.error('请求异常，请联系管理员！')
                }
                this.configLoading = false
            } catch (error) {
                this.configLoading = false
                console.log(error)
            }
        }
    },
    destroyed () {
    }
}
export default ProcessConfig
